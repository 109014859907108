import React from 'react';
import introductionStyle from './IntroductionStyle';
import { Box, Typography } from '@mui/material';
export default function Introduction() {

    return (
        <>
            <Box sx={introductionStyle}>
                <Typography
                    variant="intro"
                    component={"h1"}
                    gutterBottom>Séfer Dévarim
                </Typography>

                <Typography
                    gutterBottom
                    style={{ display: "block", width: "100%" }}
                    variant="intro" component={"blockquote"}>
                    "Si vous saviez, quel bruit fait dans les Cieux la lecture du Sefer Dévarim, vous tiendriez le Sefer Dévarim dans votre main toute la journée, et ne le lâcherez pas une seconde".
                </Typography>
                <Typography
                    gutterBottom
                    style={{ display: "block", width: "100%", margin: "15px 0" }}
                    variant="intro" component={"blockquote"}>
                    "Moshé Rabbénou est l'avocat défendeur de tout celui qui lit le Séfer Dévarim le Shabbat.<br /> Tout celui qui a un problème ou une difficulté, la force du Séfer Dévarim permet de l'adoucir, d'être délivrer, et d'ouvrir toutes les portes des Cieux."
                </Typography>
                <Typography style={{ fontWeight: "bold", textAlign: "center" }}>Rabbi Yoshiyahou Yossef Pinto.</Typography>
                <Box sx={introductionStyle.description}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/BRciKSNW1lI?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <Box>
                        <Typography variant="body1" component="p" gutterBottom>
                            Il est ramené dans le livre "Ma'assé Roqéa'h" (Paracha Vaet'hanan), un enseignement surprenant sur un verset de la paracha Ha'azinou : « Reconnaissez maintenant que c'est moi, qui suis D.ieu, moi seul, et nul D.ieu à côté de moi! Que seul je fais mourir et vivre, je blesse et je guéris, et qu'on ne peut rien soustraire à ma puissance. » (Dévarim 32, 39).<br />Il faut connaitre quel est le sens des mots : « Reconnaissez maintenant que c'est moi », comment comprendre le mot « Maintenant » ?
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom>
                            Il est possible de d'expliquer selon le Midrash (Dévarim Rabbah 11, 8), "Lorsque le moment est venu pour Moshé de partir de ce monde etc.., Moshé a dit : « Maitre du monde, une seule chose je te demande avant de mourir, que je puisse rentrer et déchirer toutes les portes des Cieux et ils ne verront qu'il n'y a personne sauf Toi ».  Il y a lieu de comprendre, où est-il mentionné qu'Hachem a entendu et exaucé la demande de Moshé Rabbénou ? Il est enseigné dans le livre "Ma'assé Roqéa'h" qui ramené les enseignements du livre "Beqol Bokhim" du Méqoubal Rabbi Avraham Galanti (Eikha 2, 21) qu'il y a dans le Ciel 955 portes (valeur numérique du mot Shamayim (Cieux), la lettre « mem » de la fin du mot à une grande valeur), vers lequel généralement l'ange Matat monte les prières du Peuple d'Israël. Cependant, il n'a la permission de monter que dans les 900 premières portes, mais pas dans les 55 dernières, ainsi qu’il est mentionné : « Vois, l'Éternel, ton D.ieu, possède les cieux et les cieux des cieux, la terre et tout ce qu'elle renferme » (Dévarim 10, 14), ce qui signifie que parmi les 955 portes des Cieux, les 55 (guématria du mot הן – Vois) sont à l'Eternel, ton D.ieu, car aucun ange n'a la permission de monter là-bas.
                        </Typography>
                        <Typography component={"a"} href={process.env.PUBLIC_URL + '/introduction_sefer_devarim.pdf'} target="_blank"   >Lire la suite</Typography></Box>
                </Box>
            </ Box >
        </>
    )
}
