import gematriya from "gematriya";
let lectureContainerStyle = {
    outerBox: {
        "@media (min-width:744px)": {
            display: "flex",
            flexDirection: "row-reverse",
            width: "100%",
            justifyContent: "space-between"
        }
    },
    pskMode: (mode: 'he' | 'fr', psk: number) => {
        return {
            "&::before": {
                content: mode === 'he' ? `' (${gematriya(psk)}) '` : `' (${psk}) '`,
                fontSize: "0.8rem",
                color: "grey"
            }, listStyleType: "none"
        }
    },
    innerBox: {
        width: "100%",
        background: "#F9F7F7",
        boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
        h6: {

            backgroundColor: "#EBE4B7",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

    },


} as const
export default lectureContainerStyle