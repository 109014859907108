import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Container, Typography } from '@mui/material';
import LectureContainer from 'common/components/LectureContainer';
import { atomData } from 'ressources/data/recoil';
import ParachiotRef from 'ParachiotRef.json';
import MenuRead from 'common/components/MenuRead';
import axios from 'axios';

export let findBookData = (params: params, data: any) => {
    let { perek, paracha, day, lang } = params
    let res: dataReadStruct = {
        type: typeof (paracha) === "string" ? 'paracha' : day ? 'day' : 'perek',
        lang: lang,
        text: [],
        ref: typeof (paracha) === "string" ? paracha : day ? day + "" : perek + "",
    }

    if (res.type === "perek") {
        res.text = data[lang].text[perek - 1];
    } else if (res.type === "day") {
        let groupBy = 5;
        let offset = day === 7 ? 1 : 0;
        let base = (groupBy * (day - 1)), limit = groupBy * day;
        res.text = data[lang].text.slice(base, limit - offset);
        res.extraRef = base;
    } else {
        let ref = ParachiotRef.nodes.filter(ref => ref.title.includes(paracha as string));
        res.text = getText(ref[0].alyot, data[res.lang].text)
    }
    return res;
}
export let getRefs = (stringRef: string) => {
    return stringRef.split('-')
        .map(element => {
            let arrToNum = element.split(":")
            return [+arrToNum[0] - 1, +arrToNum[1] - 1]
        });
}

export let getDevarim = async (lang: string) => {
    //http://www.seferdevarim.net
    let response = axios.get("https://www.seferdevarim.net/api/general/devarim/" + lang);
    return response;
}
export let getParagraphes = (start: number[], end: number[], data: string[][]): string[] => {

    let res = [] as string[];
    for (let i = start[0]; i <= end[0]; i++) {
        let j = start[1], offset = 0;
        if (i < end[0]) {
            offset = data[i].length
        }
        if (j > 0 && i > start[0]) {
            j = 0
        }
        for (; (j <= (end[1] + offset)) && (j < data[i].length); j++) {
            res.push(data[i][j])
        }
    }
    return res
}
export let getText = (ref: string[], data: string[][]) => {
    let res = [] as string[][];
    ref.forEach((ref) => {
        let tempArr = [] as string[];
        let [start, end] = getRefs(ref)
        tempArr = getParagraphes(start, end, data);
        res.push(tempArr);
    })

    return res;
}



export default function Read() {

    const [params, setParams] = useState<params>({
        perek: 1,
        paracha: 0,
        day: 0,
        lang: "he"
    });

    const [data, setData] = useRecoilState(atomData);

    const [currentRead, setCurrentRead] = useState(data)
    useEffect(() => {
        if (!data.fr || !data.he) {
            if (!data.he) {
                getDevarim(params.lang).then((result) => {
                    setData({ he: result.data });
                })
            }
            else if (data.he && params.lang === 'fr') {
                setCurrentRead({});
                getDevarim(params.lang).then((result) => {
                    setData({ ...data, fr: result.data })
                })
            }
        }

    }, [params, data, setData]);

    useEffect(() => {
        if ((data.he && params.lang === 'he') || (data.fr && params.lang === 'fr')) {
            setCurrentRead(findBookData(params, data));
        }
    }, [setCurrentRead, params, data])

    return <><Container sx={{ marginY: "100px" }}>
        <Typography
            variant="h4"
            gutterBottom
            style={{ textAlign: "center", padding: "15px 0" }}>
            Sefer Devarim en lecture libre
        </Typography>
        <MenuRead currentParams={params} handleChangeParams={setParams} />
        <LectureContainer style={{ margin: "15px 0" }} showTitle fullWidth data={currentRead} />
    </Container>
    </>
}
