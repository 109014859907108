let introductionStyle = {
    paddingTop: "30px",
    blockquote: {
        fontSize: "1.1rem"
    },
    description: {
        display: "inline-block",
        marginTop: "15px",
        iframe: {
            marginY: "10px",
            width: "100%",
            "@media (min-width:1024px)": {
                width: "60%",
                float: "left",
                margin: "20px 20px 20px 0",
            }
        },
        p: {

            padding: "10px",
            marginX: "10px",

        }
    },
    h1: {
        fontSize: "2.0rem"
    },

    a: {
        color: "inherit",
        textDecoration: "none",
        backgroundColor: "#f1ebc1",
        display: "block",
        textAlign: "center",
        borderRadius: "5px 10px",
        width: "100%",
        marginY: "10px",
        fontWeight: "bold",
        padding: "5px",
        transition: "ease 0.5s all",
        '&:hover': {
            backgroundColor: "#c8c6b7",
            cursor: "pointer"
        }
    },

    modal: {
        box: {
            width: "80%",
            backgroundColor: "white",
            margin: "5vh auto",
            padding: "15px",
            height: "85vh",
            overflow: "scroll"
        }
    }
} as const;

export default introductionStyle;