import React, { useState } from 'react';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { ReactComponent as YoutubeIcon } from "ressources/images/svg/youtubeIcon.svg";
import ShuvaLogo from "ressources/images/img/shouvaLogo.png";
import { Button, DialogContent, AppBar, Typography, Toolbar, SvgIcon, Box, Modal, Alert, Snackbar, Menu, MenuItem } from '@mui/material';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Link } from 'react-router-dom';
import navStyle from './navStyle';
import FormLecture from '../FormLecture';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Nav(props: { toReduce: boolean }) {
    const matches = useMediaQuery('(min-width:744px)')
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [success, setSuccess] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuIsOpen = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e: any) => {
        if (e.target.innerText === "En Euro") {
            window.open("https://www.paypal.com/donate/?hosted_button_id=VU6XRRBW6LGQ6", '_blank');
        } else if (e.target.innerText === "En Shekel") {
            window.open("https://www.paypal.com/donate/?hosted_button_id=JKVQM73L4VP84", '_blank')
        };
        setAnchorEl(null);
    };
    return (
        <>
            <AppBar position="fixed" >
                <Toolbar sx={navStyle} style={props.toReduce && matches ? { height: "80px" } : {}}>
                    <Box sx={navStyle.stack}>
                        <Button
                            component={Link}
                            sx={navStyle.button}
                            to="/lecture"
                            disableElevation
                            startIcon={<MenuBookIcon />}
                            variant="contained"
                        >
                            <Typography
                                variant="button"
                            >
                                Lire
                            </Typography>
                        </Button>
                        <Button disableRipple sx={navStyle.logo} style={props.toReduce && matches ? { left: "50.5%", width: "90px" } : {}} component={Link} to="/">
                            <img
                                style={loaded ? {} : { display: 'none' }}
                                src={ShuvaLogo}
                                onLoad={() => setLoaded(true)}
                                alt="logo de Shouva Israel" />
                        </Button >
                        <Button

                            onClick={() => { setOpen(true) }}
                            disableElevation
                            startIcon={<BookmarkAddIcon />}
                            variant="contained"
                        >
                            <Typography
                                variant="button"
                            >
                                Nouvelle <br />lecture
                            </Typography>

                        </Button>
                    </Box>
                    <Box sx={navStyle.rightStack} >
                        <Button
                            sx={{ margin: "0 10px" }}
                            id="demo-positioned-button"
                            variant="contained"
                            startIcon={<VolunteerActivismIcon />}
                            aria-controls={menuIsOpen ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            disableElevation
                            aria-expanded={menuIsOpen ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <Typography
                                variant="button">
                                Faire un don
                            </Typography>

                        </Button>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={menuIsOpen}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem
                                onClick={handleClose}
                            >En Euro</MenuItem>
                            <MenuItem onClick={handleClose}
                            >En Shekel</MenuItem>
                        </Menu>
                        <Button
                            disableElevation
                            sx={navStyle.youtube}
                            variant="contained"
                            startIcon={<SvgIcon component={YoutubeIcon} inheritViewBox />}
                            href="https://www.youtube.com/channel/UCHHWmFSnT8g5xABrvsk2vLw" target="_blank">
                            <Typography
                                variant="button"
                            >Chaine<br /> Youtube</Typography>
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar >
            <Modal
                open={open}
                style={{ overflowY: "scroll" }}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogContent style={{ width: "80%", backgroundColor: "#fff", height: "80vh", margin: "20px auto" }}>
                    <Box sx={{ width: "100%" }}>
                        <FormLecture handleClose={setOpen} postSucceedHandler={setSuccess} />
                    </Box >
                </DialogContent>
            </Modal>
            <Snackbar open={success} autoHideDuration={6000} onClose={() => { setSuccess(false) }}>
                <Alert onClose={() => { setSuccess(false) }} severity="success" sx={{ width: '100%' }}>
                    Le lien d'activation a été envoyer!
                </Alert>
            </Snackbar>
        </>
    )
}
