import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Container, DialogContent, Modal, Paper, CircularProgress, Skeleton, Snackbar, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import useMediaQuery from '@mui/material/useMediaQuery';
import bookStyle from './bookStyle';
import ParachiotRef from 'ParachiotRef.json';
import ExpandableMenu from 'common/components/ExpandableMenu';
import TableMenu from 'common/components/TableMenu';
import { atomData } from 'ressources/data/recoil';
import { useRecoilState } from 'recoil';
import lectureContainerStyle from 'common/components/LectureContainer/lectureContainerStyle';
import { getDevarim, getParagraphes, getRefs } from '../Read';
import { parachiot } from 'common/components/MenuRead';
import gematriya from 'gematriya';
import { alyot, prefixes, reasons } from "ressources/data/interpreter";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { firstCharToUpper, generateParachaData, generatePartToRead } from 'ressources/utils/utils';

type UpdatePartResponse = {
    success: boolean,
    reinitialised: boolean | undefined
}
type keyofObj<T> = keyof T;
type DataBook = {
    "_id": string,
    firstName: string,
    parentName: string,
    title: string | undefined,
    partToRead: number,
    prefix: keyofObj<typeof prefixes>,
    customReason: string | undefined,
    mode: string,
    readedBook: number,
    reason: keyofObj<typeof reasons>,
    data: boolean[] | boolean[][]
}
export default function Book() {

    let isDesktop = useMediaQuery('(min-width:1024px)');
    let isTablet = useMediaQuery('(min-width:768px)');
    let { id } = useParams();
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [params, setParams] = useState({
        ref: [] as number[],
        lang: "he"
    });
    const [success, setSuccess] = useState(false);
    const [dataBook, setDataBook] = useState<DataBook | undefined>(undefined);
    const [data, setData] = useRecoilState(atomData);
    const [currentRead, setCurrentRead] = useState<null | string[] | object>(null);
    let handleRead = (ref: number[]) => {
        setParams({ ...params, ref: ref })
        setOpen(true);
    }

    useEffect(() => {
        if (!dataBook) {
            //http://www.seferdevarim.net
            console.log(process.env.mode)
            axios.get("https://www.seferdevarim.net/api/book/" + id).then((result) => {

                if (result.data !== "") {
                    let data = result.data as DataBook;
                    setDataBook({
                        ...data,
                        title: data.customReason ?
                            data.customReason
                            :
                            `Lecture ${firstCharToUpper(reasons[data.reason])} de ${firstCharToUpper(data.firstName)} ${prefixes[data.prefix]} ${firstCharToUpper(data.parentName)}`,
                        partToRead: data.mode === "paracha" ?
                            generatePartToRead(data.data as boolean[][])
                            :
                            (data.data as boolean[]).filter((value: boolean) => !value).length
                    } as DataBook);
                } else {
                    return navigate("/404")
                }

            }).catch((e) => {

                return navigate("/404")
            })
        }
    }, [dataBook, id, navigate]);


    useEffect(() => {
        if (!data.fr || !data.he) {
            if (!data.he) {
                getDevarim(params.lang).then((result) => {
                    setData({ he: result.data });
                })
            }
            else if (data.he && params.lang === 'fr') {
                setCurrentRead({});
                getDevarim(params.lang).then((result) => {
                    setData({ ...data, fr: result.data })
                })
            }
        }
    }, [params, data, setData]);


    useEffect(() => {
        if (open) {
            if (dataBook) {
                if (data[params.lang]) {
                    if (dataBook.mode === 'paracha') {
                        let stringRef = ParachiotRef.nodes[params.ref[0]].alyot[params.ref[1]]
                        let [start, end] = getRefs(stringRef);
                        setCurrentRead(getParagraphes(start, end, data[params.lang].text))
                    }
                    if (dataBook.mode === 'perek') {
                        setCurrentRead(data[params.lang].text[params.ref[0]])
                    }
                }
            }

        }
    }, [open, data, params, dataBook]);

    let submitRead = () => {
        if (dataBook) {
            let { ref } = params;
            //http://www.seferdevarim.net
            axios.put(`https://www.seferdevarim.net/api/book/readedPart`, { id: id, ref: ref })
                .then((response) => {
                    let { success, reinitialised } = response.data as UpdatePartResponse;
                    let { mode, data, partToRead, readedBook } = dataBook;
                    let dataSchema: boolean[] | boolean[][] = data;
                    if (success) {
                        if (mode === "paracha") {
                            dataSchema = dataSchema as boolean[][];
                            dataSchema[ref[0]][ref[1]] = true;
                            if (partToRead === 0) {
                                readedBook++;
                            }
                        }
                        else {
                            dataSchema = dataSchema as boolean[];
                            dataSchema[ref[0]] = true;
                        }

                        if (reinitialised) {
                            setDataBook({ ...dataBook, data: dataBook.mode === "paracha" ? generateParachaData() : Array(34).fill(false) })
                        } else {
                            setDataBook({ ...dataBook, data: dataSchema, partToRead: --partToRead, readedBook: readedBook })
                        }
                        setSuccess(true);
                    } else {

                        setDataBook(undefined);
                    }


                })
            setOpen(false);
        }


    }

    return <Container>
        {dataBook ? <><Typography variant="h3" sx={{ paddingY: "10px", textAlign: "center" }} >{dataBook.title}</Typography>
            <Box sx={bookStyle.firstSection}>
                <Box sx={{ display: { sm: "flex" }, alignItems: "center", justifyContent: { sm: "space-evenly" } }}>

                    <Box >
                        <Typography variant="body1" >
                            Il ne reste plus que {dataBook.partToRead} {dataBook.mode === "paracha" ? "montée" : "chapitre"}{dataBook.partToRead > 0 ? "s " : " "}
                            a lire pour conclure le livre !
                        </Typography>
                        {dataBook.readedBook > 0 && (
                            <Typography variant="body1" >
                                Cette chaine a déjà conclue {dataBook.readedBook} fois le livre!
                            </Typography>
                        )}
                        <Typography variant="body1" >
                            Partagez autour de vous pour que l'on puisse finir la lecture du livre!
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: { sm: "column" }, justifyContent: { xs: "space-between" } }}>
                        <Button
                            style={{ ...bookStyle.button, width: isTablet ? "100%" : "49%", backgroundColor: "#4894CE" }}
                            component={FacebookShareButton}
                            url={`https://www.seferdevarim.net/book/${id}`}
                            startIcon={<FacebookIcon />}
                        >
                            <Typography variant="button"> Partager sur Facebook</Typography>
                        </Button>
                        <Button
                            style={{ ...bookStyle.button, width: isTablet ? "100%" : "49%", backgroundColor: "#00E676" }}
                            component={WhatsappShareButton}
                            url={`https://www.seferdevarim.net/book/${id}`}
                            startIcon={<WhatsAppIcon />}>
                            <Typography variant="button"> Partager sur Whatsapp</Typography>
                        </Button>
                    </Box>
                </Box>

                <Typography variant='h3' >
                    Lire {dataBook.mode === 'paracha' ? "une montée" : "un chapitre"}
                </Typography>
                {dataBook.mode === 'paracha' ? isDesktop ?
                    <TableMenu handleRead={handleRead} data={dataBook.data as boolean[][]} />
                    :
                    <ExpandableMenu handleRead={handleRead} data={dataBook.data as boolean[][]} />
                    :
                    <Box sx={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                        {dataBook.data.map((chapter, index) => {
                            return <Button
                                key={index}
                                variant={"contained"}
                                onClick={(() => {
                                    if (!chapter) {
                                        handleRead([index])
                                    }
                                })}
                                sx={{ width: "200px", marginY: "10px", textTransform: "initial" }} disabled={chapter && true}> {`פרק ${gematriya(index + 1)} - Chapitre ${index + 1} `} </Button>
                        })}
                    </Box>
                }

            </Box>
            <Snackbar open={success} autoHideDuration={6000} onClose={() => { setSuccess(false) }}>
                <Alert onClose={() => { setSuccess(false) }} severity="success" sx={{ width: '100%' }}>
                    Lecture enregistrée.
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                style={{ overflowY: "scroll" }}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogContent style={{ width: "80%", backgroundColor: "#fff", height: "80vh", margin: "20px auto" }}>
                    <Box sx={{ width: "100%", height: "inherit" }} >
                        {currentRead && Array.isArray(currentRead) ?
                            (
                                <>
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <Button disabled={params.lang === "fr"} onClick={() => { setParams({ ...params, lang: 'fr' }) }} sx={{ marginRight: "5px" }} variant="contained">Francais</Button>
                                        <Button disabled={params.lang === "he"} onClick={() => { setParams({ ...params, lang: 'he' }) }} variant="contained" >Hebreu</Button>
                                    </Box>
                                    {dataBook.mode === "paracha" ? <Typography variant="h3">{parachiot[params.ref[0]]} - {alyot['fr'][params.ref[1]]} montée</Typography>
                                        : <Typography variant="h3">{`פרק ${gematriya(params.ref[0] + 1)} - Chapitre ${params.ref[0] + 1} `}</Typography>}
                                    <Paper dir={params.lang === "fr" ? "ltr" : "rtl"} elevation={0} >
                                        {currentRead.map((paragraph: string | string[], index: number) => {
                                            return <Typography key={index} variant={"hebrewFont"} component="p" style={{ margin: "0 15px" }} sx={lectureContainerStyle.pskMode(params.lang as 'he' | 'fr', index + 1)} dangerouslySetInnerHTML={{ __html: `${paragraph}` }} />
                                        })}
                                        <Button variant="contained" sx={{ textTransform: "initial", margin: "20px" }} size={'large'} onClick={submitRead}>J'ai lu le passage</Button>
                                    </Paper>

                                </>

                            )
                            : <Box sx={{ padding: "10px 0", width: "100%", height: "inherit", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <CircularProgress size={60} color="secondary" />
                            </Box >
                        }
                    </Box>
                </DialogContent>
            </Modal>
        </> :
            <>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", marginY: "30px" }}>
                    <Skeleton variant="circular" width={"50vw"} style={{ maxWidth: "250px", maxHeight: "250px", marginTop: "20px" }} height={"50vw"} animation="wave" />
                    <div style={{ margin: "10px 0", width: "inherit" }}>
                        <Skeleton variant="text" width={"100%"} animation="wave" />
                        <Skeleton variant="text" width={"100%"} animation="wave" />
                        <Skeleton variant="text" width={"100%"} animation="wave" />
                        <Skeleton variant="text" width={"100%"} animation="wave" />
                    </div>

                    <Skeleton variant="rectangular" width={"100%"} height={"20vw"} animation="wave" />
                </Box>
            </>
        }

    </Container>
}
