import React, { useState, useEffect } from 'react'
import { Box, Typography, CardContent, CardMedia, Card, Chip, Divider, Skeleton } from '@mui/material';
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import deliveranceStyle from './deliveranceStyle';
import axios from 'axios';
type DelivranceData = {
    imgLink: string,
    text: string
}
export default function Deliverance() {
    let { card, container } = deliveranceStyle;
    const [data, setData] = useState<DelivranceData[] | undefined>(undefined);
    useEffect(() => {
        if (!data) {

            axios.get("https://www.seferdevarim.net/api/yechouot").then((res: any) => {
                setData(res.data as DelivranceData[]);
            }).catch((e) => {
                console.error(e)
            })
        }
    }, [data])
    return (
        <Box sx={container}>
            <Typography variant="h3" gutterBottom >
                La force du Séfer Dévarim
            </Typography>
            {data ? <>
                <Swiper
                    style={{ color: '#fff' }}
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    spaceBetween={5}
                    navigation
                    pagination={{
                        "clickable": true
                    }}
                    breakpoints={{
                        768: {
                            slidesPerView: 2
                        },
                        1024: {
                            slidesPerView: 3
                        }
                    }}
                >
                    {data.map((element, index) => {
                        return <SwiperSlide key={index}>
                            <Card sx={card} elevation={0}>
                                <CardMedia
                                    component="img"
                                    image={element.imgLink}
                                    alt={`Delivrance numero ${index}`}
                                />
                                <Divider textAlign='center' sx={{ marginY: "5px" }} >
                                    <Chip label="Traduction" />
                                </Divider >
                                <CardContent>
                                    <Typography variant="body2" style={{ fontSize: "0.8rem" }} color="text.secondary" dangerouslySetInnerHTML={{ __html: `${element.text}` }} />
                                </CardContent>
                            </Card>
                        </SwiperSlide>
                    })}
                </Swiper>
            </> : <Box sx={{ width: "100%", " > *": { marginY: "10px" } }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Box>
            }

        </Box >
    )
}
