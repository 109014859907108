import { variables } from "ressources/Mui-styles/MuiTheme";
let global = {
    marginTop: variables.navHeightMobile + "px",
    "@media (min-width:744px)": {
        marginTop: variables.navHeightTablet + 80 + "px"
    },
    fabBox: {
        display: { sm: "none", xs: "flex" },
        flexDirection: "column",
        justifyContent: "center",
        color: "#fff",
        position: "fixed",
        zIndex: 1000,
        bottom: "16px",
        right: "16px",
    },
    donateBtn: {
        minWidth: "56px",
        width: "56px",
        height: "56px",
        borderRadius: "100%"
    },
    youtubeFab: {
        backgroundColor: "red",
        marginTop: "5px",
        ":hover": {
            svg: {
                color: "black"
            }

        }
    },
} as const;
export default global;