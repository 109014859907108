import React, { useEffect, useState } from 'react'
import { Container, Box, CircularProgress, Alert, AlertTitle, AlertColor, Button } from '@mui/material';
import valid from "ressources/lottie/valid.json";
import attention from "ressources/lottie/attention.json";
import invalid from "ressources/lottie/invalid.json";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Lottie from 'lottie-react';
type ActivationResponse = { code: number, msg: string, success: AlertColor };
type OptionsPage = { svg: any, msg: string, title: string, alert: AlertColor }
export default function Activation() {
    const [response, setResponse] = useState<OptionsPage | null>(null);
    let { id } = useParams();
    useEffect(() => {
        if (!response) {
            //http://www.seferdevarim.net
            axios.put("https://www.seferdevarim.net/api/book/activation/" + id).then(({ data }) => {
                data = data as ActivationResponse;
                let result: OptionsPage;

                if (data.code === 0) {
                    result = {
                        svg: attention,
                        title: data.msg,
                        msg: "Se lien d'activation a déja été visité",
                        alert: 'warning'
                    }
                } else if (data.code === -1) {
                    result = {
                        svg: invalid,
                        title: data.msg,
                        msg: "Le lien est peut être périmé ou invalide",
                        alert: 'error'
                    }
                } else {
                    result = {
                        svg: valid,
                        title: data.msg,
                        msg: "La lecture a été validée avec succès!",
                        alert: 'success'
                    }
                }
                setResponse(result);
            })
        }

    })
    return (
        <Container>

            <Box sx={{ minHeight: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                {response ? <>
                    <Lottie style={{ width: "20%" }} animationData={response.svg} />
                    <Alert severity={response.alert} sx={{ marginBottom: "10px" }}>
                        <AlertTitle>{response.title}</AlertTitle>
                        {response.msg}
                    </Alert>
                    <Button variant="contained" component={Link}
                        to="/">Retour a la page d'acceuil</Button>
                </> :
                    <CircularProgress size={300} />}
            </Box>
        </Container>
    )
}
