export const alyot = {
    fr: ["Première", "Deuxième", "Troisième", "Quatrième", "Cinquième", "Sixième", "Septième"],
    he: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שביעי"]
}
export const reasons = {
    1: "pour la réussite",
    2: "a la mémoire",
    3: "pour la guérison"
}
export const prefixes = {
    1: "ben",
    2: "bat"
}
export const dayOfWeek = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Shabbat"];
export const parachiot = {
    fr: ["Devarim", "Vaethanan", "Eikev", "Re'eh", "Choftim", "Ki Tetse", "Ki Tavo", "Nitsavim", "Vayelekh", "Haazinou", "Vezot Haberaha"],
    he: ["דברים", "ואתחנן", "עקב", "ראה", "שופטים", "כי תצא", "כי תבוא", "ניצבים", "וילך", "האזינו", "וזאת הברכה"]
};