import { variables } from "ressources/Mui-styles/MuiTheme"
let navStyle = {
    transition: "all 0.8s ease",
    height: variables.navHeightMobile + "px",
    "@media (min-width:744px)": {
        height: variables.navHeightTablet + "px",

    },
    "@media (min-width:1040px)": {

    },
    stack: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        "@media (min-width:744px)": {
            justifyContent: "start",
            height: "100%",

            button: {
                margin: "0 10px"
            }
        }
    },
    logo: {
        display: "flex",
        justifyContent: "center",
        padding: 0,
        width: "65px",
        margin: 0,
        transition: "all 0.8s ease",
        "@media (min-width:744px)": {

            width: "107px",
            position: "absolute",
            left: " 50%",
            marginLeft: "-50px",
            top: "60%",
            marginTop: "-50px",
        },
        img: {
            width: "100%",
            height: "auto",
        }
    },
    button: {
        minWidth: "107px",
        backgroundColor: "#fff",
        height: "45px",
        borderRadius: "20px",

    },
    rightStack: {
        display: "none",
        "@media (min-width:744px)": {
            display: "flex"
        },
    },
    youtube: {
        svg: {
            fill: "none",
        },
        color: "#fff",
        borderRadius: "20px",
        backgroundColor: "#E42B2B",
        "&:hover": {
            backgroundColor: "#B52929"
        }

    }
} as const;
export default navStyle;