import React from 'react'
import Introduction from 'common/components/Introduction';
import DailyLecture from 'common/components/DailyLecture';
import { Container, Box } from '@mui/material';
import LectureParticipate from 'common/components/LectureParticipate';
import Deliverance from 'common/components/Deliverance';
import VideoRav from 'common/components/VideoRav';
import homeStyle from './homeStyle';

export default function Home() {
    console.log(process.env.NODE_ENV)
    return (
        <>
            <Container >
                <Introduction />
                <DailyLecture />
                <Box sx={homeStyle.responsiveBox}>
                    <LectureParticipate />
                    <Deliverance />
                </Box>

            </Container >
            <VideoRav />
        </>
    )
}
