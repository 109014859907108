let bookStyle = {
    firstSection: {
        "> *": {
            marginY: "30px"
        }
    },

    button: {
        margin: "20px 0",
        padding: "6px 10px",
        color: "#fff",

    }

} as const;
export default bookStyle;