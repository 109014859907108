let formStyle = {
    position: "relative",
    marginX: "auto",



    p: 3,
    small: {
        fontSize: "0.9rem",
        lineHeight: "1.6rem",
        marginY: "10px"

    },
    label: {
        color: "black!important"
    }

} as const;
export default formStyle;