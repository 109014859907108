import React from 'react';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Button } from '@mui/material';
import { parachiot } from "../MenuRead"
import { alyot } from "ressources/data/interpreter";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export default function ExpendableMenu({ data, handleRead }: { data: boolean[][], handleRead: (ref: number[]) => void }) {
    const [expanded, setExpanded] = React.useState<boolean | string>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    return <div>

        {data.map((paracha, pIndex) => {
            let toReadSize = paracha.filter(alya => !alya).length
            return <Accordion key={pIndex} expanded={expanded === `panel${pIndex}`} onChange={handleChange(`panel${pIndex}`)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${pIndex}-content`}
                    id={`panel${pIndex}-header`}
                >

                    <Typography variant="body2">{parachiot[pIndex] + " "} ({toReadSize}/{paracha.length})</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
                    {paracha.map((alya, index) => {
                        return <Button
                            variant="contained"
                            sx={{ marginY: "10px" }}
                            onClick={() => {
                                if (!alya) {
                                    handleRead([pIndex, index]);
                                }
                            }}
                            key={index}
                            disabled={alya}>{alyot.fr[index]}</Button>

                    })}
                </AccordionDetails>
            </Accordion>
        })}


    </div>;
}
