import React, { useState } from 'react'
import formStyle from './formStyle';
import CloseIcon from '@mui/icons-material/Close';
import { format, isAfter, isBefore, add, isValid, isToday } from 'date-fns';
import { Box, FormControl, Grid, TextField, MenuItem, Typography, Divider, Button, RadioGroup, FormControlLabel, Radio, Alert } from "@mui/material";
import { checkStringSize, hasSpecialChars, isEmail } from 'ressources/utils/utils';
import axios from 'axios';
let FormLecture = ({ handleClose, postSucceedHandler }: FormLectureProps) => {
    const [form, setForm] = useState<FormLecture>({
        mail: "",
        reason: 1,
        firstName: "",
        prefix: 1,
        parentName: "",
        mode: "paracha",
        dateStart: new Date().getTime(),
        dateEnd: add(new Date(), { months: 2 }).getTime(),
        submitErr: false
    });
    let handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value, name }: { value: string | Date | null | number, name: string } = e.target

        let res: boolean;
        switch (name) {
            case "firstName":
            case "parentName":
                res = checkStringSize(value, 30, "greater") || hasSpecialChars(value);
                break;
            case "mail":
                res = !(checkStringSize(value, 30, "lesser") && isEmail(value));
                break;
            case "dateStart":
            case "dateEnd":
                if (isValid(new Date(value))) {
                    let dateToCheck = new Date(value).getTime(), now = new Date().getTime();
                    if (name === "dateStart") {
                        res = (isBefore(dateToCheck, now) && !isToday(dateToCheck)) || (form.dateEnd !== null ? isAfter(dateToCheck, new Date(form.dateEnd).getTime()) : false);
                    }
                    else {
                        let maxTime = add(form.dateStart, { months: 2 }).getTime();
                        res = isBefore(dateToCheck, now) || isBefore(dateToCheck, new Date(form.dateStart).getTime()) || isAfter(dateToCheck, maxTime);
                    }
                    value = new Date(value).getTime()
                }
                else {
                    res = true
                    value = form[name];
                }
                break;
            default:
                res = false;
        }

        setForm(
            {
                ...form,
                [name]: value,
                formErr: {
                    ...form.formErr,
                    [name]: res
                },
                submitErr: (form.submitErr && !Object.values(form.formErr as object).includes(true))
            });
    };
    let handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        if (Object.values(form.formErr as object).includes(true)) {
            setForm({ ...form, submitErr: true })
        } else {
            delete form.submitErr;
            delete form.formErr;
            axios.post("https://www.seferdevarim.net/api/book/newBook", form)
                .then(response => response.status === 200 ? postSucceedHandler(true) : console.log(response.data));
            handleClose(false)
        }
    }

    return (
        <Box sx={formStyle}>
            <CloseIcon onClick={() => handleClose(false)} style={{ position: "absolute", cursor: "pointer", top: "0", right: "0" }} />
            <Typography variant="h6">
                Ouvrir une Lecture
            </Typography>
            <Divider variant="fullWidth" sx={{ margin: "10px 0" }} />
            <form onSubmit={handleSubmit}>
                <FormControl>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                name="mail"
                                fullWidth
                                required
                                variant="outlined"
                                error={form.formErr?.mail}
                                helperText={form.formErr?.mail && "Veuillez entrer une adresse mail valide"}
                                type="email"
                                margin="dense"
                                onChange={handleChange}
                                placeholder='email@gmail.com...' />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">Lecture pour : </Typography>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                value={form.reason}
                                select
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                name="reason"
                                onChange={handleChange}
                                label="Raison de la lecture"
                            >
                                <MenuItem value={1}>Pour la réussite</MenuItem>
                                <MenuItem value={2}>A la mémoire</MenuItem>
                                <MenuItem value={3}>Pour la guérison</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">De : </Typography>
                        </Grid>
                        <Grid item sm={5}>
                            <TextField
                                name="firstName"
                                label="Nom"
                                required
                                onChange={handleChange}
                                variant="outlined"
                                error={form.formErr?.firstName}
                                helperText={form.formErr?.firstName && "Le champ ne doit pas comporter de caractere special ou avoir plus de 30 lettres"}
                                fullWidth
                                id="outlined-required"
                                margin="dense"
                            />
                        </Grid>
                        <Grid item sm={2}>
                            <TextField
                                value={form.prefix}
                                select
                                name="prefix"
                                fullWidth
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"

                            >
                                <MenuItem value={1}>Ben / Fils de</MenuItem>
                                <MenuItem value={2}>Bat / Fille de</MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item sm={5}>
                            <TextField
                                name="parentName"
                                label="Nom de la mere"
                                required
                                error={form.formErr?.parentName}
                                helperText={form.formErr?.parentName && "Le champ ne doit pas comporter de caractere special ou avoir plus de 30 lettres"}
                                variant="outlined"
                                onChange={handleChange}
                                id="outlined-required"
                                margin="dense"
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2">Debute le: </Typography>

                            <TextField
                                fullWidth
                                name="dateStart"
                                error={form.formErr?.dateStart}
                                helperText={form.formErr?.dateStart && "La date indiquee n'est pas valide "}
                                defaultValue={format(form.dateStart, "yyyy-MM-dd")}
                                onChange={handleChange}
                                type="date" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2">Se termine le:</Typography>

                            <TextField
                                fullWidth
                                type="date"
                                name="dateEnd"
                                error={form.formErr?.dateEnd}
                                helperText={form.formErr?.dateEnd && "La date indiquee n'est pas valide / Le delais est plus long que deux mois"}
                                defaultValue={format(form.dateEnd, "yyyy-MM-dd")}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">Divisée par : </Typography>
                            <RadioGroup
                                name="mode"
                                onChange={handleChange}
                                aria-labelledby="divisee par,groupe de bouton"
                                defaultValue="paracha"
                            >
                                <FormControlLabel value="paracha" control={<Radio color="secondary" />} label="Montées" />
                                <FormControlLabel value="perek" control={<Radio color="secondary" />} label="Chapitres" />
                            </RadioGroup>
                        </Grid>
                    </Grid>


                    <Typography variant="subtitle1" component="small" >
                        Le livre peut etre relue autant de fois que possible durant se laps de temp.<br />
                        Afin de valider l'ouverture du livre un lien d'activation sera envoyer a l'email souscris.
                    </Typography>
                    <Typography variant="subtitle2" component="small">
                        Il est possible d'ouvrir une lecture pendant un delais maximum de deux mois.<br />
                        Le lien d'activation expire dans les deux heures qui suivent la demande d'activation.
                    </Typography>

                    {form.submitErr && <Alert severity="error" sx={{ width: '100%' }}>
                        Le formulaire contient des champs invalides.<br />
                        Veuillez les corriger.
                    </Alert>}
                    <Button
                        style={{ maxWidth: "100px", margin: "0 auto" }}
                        type="submit"
                        variant="contained"
                    >
                        Envoyer
                    </Button>
                </FormControl>
            </form>

        </Box>

    )
}



export default FormLecture;

