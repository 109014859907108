let deliveranceStyle = {
    container: {
        "@media (min-width:1024px)": {

        },

        ".swiper-button-next,.swiper-button-prev": {
            color: "grey!important",
            "@media (min-width:600px)": {
                opacity: "0.5",
                "&:hover": {
                    opacity: 1
                }
            },

        }
    },
    card: {

        p: {
            padding: "10px 15px",
            maxWidth: "600px",

            margin: "auto"

        },
        img: {
            margin: "auto",
            maxWidth: "400px"
        }
    }
}
export default deliveranceStyle;