let lectureParticipateStyle = {



    paddingX: "5px",
    height: "375px",
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",

    h6: {
        fontWeight: "700",
        lineHeight: "1.0rem",
        backgroundColor: "#ebe4b7",
        display: "flex",
        borderRadius: "10px 10px 0 0",
        padding: "5px"
    }
} as const
export default lectureParticipateStyle;