let footerStyle = {
    backgroundColor: "#E4A737",
    color: "#fff",
    padding: "10vh 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    h5: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "2.0rem"
    },
    form: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px 0",
        width: "100%",
        div: {
            margin: "5px",
            "@media (min-width:744px)": {
                width: "100%"
            }
        },
        "@media (min-width:744px)": {
            width: "80%",
            maxWidth: "700px",
            margin: "auto"
        }
    },
    boxInput: {
        display: "flex",
        flexDirection: "column",
        "@media (min-width:744px)": {
            flexDirection: "row",
            " >*": {
                justifyContent: "space-between",
                marginX: "5px"
            }
        }
    }

} as const;
export default footerStyle;