let dailyLectureStyle = {
    margin: "100px 0",

    outerBox: {
        ">*": {
            marginY: "20px"
        },
        "@media (min-width:744px)": {
            display: "flex",
            flexDirection: "row-reverse",
            width: "100%",
            justifyContent: "space-between"
        }
    },
    innerBox: {
        width: {
            sm: "49%"
        },
        background: "#F9F7F7",
        boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
        h5: {
            fontSize: "1.4rem",
            backgroundColor: "#EBE4B7",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        ul: {
            li: {
                padding: "10px"
            }

        }
    },


} as const
export default dailyLectureStyle