let menuReadStyle = {
    boxMenu: {

    },
    desc: {
        backgroundColor: "primary.main",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: "10px 0",
        cursor: "pointer",
    },
    menuContain: {
        backgroundColor: "#DDDAC11A",
        padding: "15px 10px"

    }
} as const
export default menuReadStyle;
