import React, { useEffect, useState } from 'react';
import lectureParticipateStyle from './lectureParticipateStyle';
import { Box, Card, CardActions, CardContent, Button, Typography, CardHeader, Skeleton } from '@mui/material';
import { reasons, prefixes } from 'ressources/data/interpreter';
import { Link } from 'react-router-dom';
import { Grid, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import axios from 'axios';
import { firstCharToUpper, generatePartToRead } from 'ressources/utils/utils';
type BookPrev = {
    "_id": string,
    firstName: string,
    mode: string,
    customReason: string | undefined,
    parentName: string,
    data: boolean[] | boolean[][],
    prefix: '1' | '2',
    readedBook: number,
    reason: '1' | '2' | '3'
}
export default function LectureParticipate() {
    const [data, setData] = useState<BookPrev[] | undefined>(undefined);
    useEffect(() => {

        if (!data) {
            axios.get("https://www.seferdevarim.net/api/book/prev").then((res: any) => {
                setData(res.data as BookPrev[]);

            }).catch((e) => {
                console.error(e)
            })
        }

    }, [data])
    return (
        <Box >
            <Typography variant="h3" gutterBottom >
                Participer à une lecture
            </Typography>
            <Swiper
                style={{ padding: "5px" }}
                modules={[Grid, Pagination]}
                slidesPerView={2}
                grid={{
                    "rows": 2,
                    "fill": "row"
                }}
                spaceBetween={10}
                pagination={{
                    "clickable": true
                }}
                breakpoints={{
                    320: {
                        grid: {
                            "rows": 2
                        },
                        slidesPerView: 1
                    },
                    600: {
                        grid: {
                            "rows": 2
                        },
                        slidesPerView: 2
                    },
                    1024: {
                        grid: {
                            "rows": 2
                        },
                        slidesPerView: 3
                    }
                }}>
                {data ? <>
                    {data.map((element, index) => {
                        let {
                            firstName,
                            mode,
                            parentName,
                            data,
                            prefix,
                            _id,
                            readedBook,
                            reason, customReason } = element;
                        let title = customReason ? customReason
                            :
                            `${firstCharToUpper(reasons[reason])} de ${firstCharToUpper(firstName)} ${prefixes[prefix]} ${firstCharToUpper(parentName)}`;

                        let partToRead = mode === "paracha" ? generatePartToRead(data as boolean[][]) : (data as boolean[]).filter((value: boolean) => !value).length;

                        return (<SwiperSlide key={index} style={{}}>
                            <Card sx={lectureParticipateStyle} >
                                <CardHeader

                                    title={title} />

                                <CardContent >
                                    {readedBook > 0 && (
                                        <Typography  >
                                            Le livre à été lu {readedBook} fois!
                                        </Typography>
                                    )}
                                    <Typography >
                                        Plus que {partToRead} {mode === "paracha" ? "montée" : "chapitre"}{partToRead > 1 && "s"} à lire pour cloturer le {readedBook > 0 && "prochain"} livre!
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Button component={Link} to={"/book/" + _id} variant="contained" color="primary">Participer à la lecture</Button></CardActions>
                            </Card>
                        </SwiperSlide>
                        )
                    })}
                </>
                    : <Box sx={{ width: "100%", " > *": { marginY: "10px" } }}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Box>
                }
            </Swiper>


        </Box >
    )
}
