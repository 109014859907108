import { createTheme } from "@mui/material";
import { TypographyOptions } from '@mui/material/styles/createTypography';
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        intro: true;
        hebrewFont: true;
    }
}

interface ExtendedTypographyOptions extends TypographyOptions {
    intro: React.CSSProperties;
    hebrewFont: React.CSSProperties;
}
export let variables = {
    main: "#F1EBC1",
    light: "#F9F7F7",
    textLightColor: "#FFFFFF",
    error: "#f44336",
    textDarkColor: "#0000",
    dividerColor: "#BDBDBD",
    navHeightMobile: 105,
    navHeightTablet: 110,
}
export let mainTheme = createTheme({
    palette: {
        primary: {
            light: variables.light,
            main: variables.main,
        }
    }, typography: {
        fontSize: 16,
        intro: {
            fontFamily: "Stoke, Roboto,sans-serif",
            textAlign: "center",
        },
        hebrewFont: {
            fontSize: "1.6rem",
            fontFamily: "ShlomoSemiStam,Roboto,sans-serif",
            lineHeight: "2.5"
        }
    } as ExtendedTypographyOptions,
    breakpoints: {
        values: {
            xs: 0,
            sm: 744,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {

        MuiTypography: {
            variants: [
                {
                    props: {
                        variant: "h3"
                    },
                    style: {
                        fontSize: "2.5rem",
                        margin: "100px 0",
                        textAlign: "center",


                        ":after": {
                            content: "''",
                            display: "block",
                            margin: "auto",
                            width: "50px",
                            paddingTop: "8px",
                            borderBottom: `4px solid #979393`,
                            opacity: "0.6",
                        }

                    }
                },
                {
                    props: {
                        variant: "button"
                    },
                    style: {
                        fontSize: "0.7rem",
                        lineHeight: "initial",
                    }
                },
            ]
        },

    }
});