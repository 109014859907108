import React, { useState } from 'react';
import { Box, Typography, Collapse, Divider, TextField, MenuItem, Grid } from '@mui/material';
import menuReadStyle from './menuReadStyle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import gematriya from 'gematriya';
import { dayOfWeek } from 'ressources/data/interpreter';

export let parachiot = ["Devarim", "Vaethanan", "Eikev", "Re'eh", "Choftim", "Ki Tetse", "Ki Tavo", "Nitsavim", "Vayelekh", "Haazinou", "Vezot Haberaha"];
export let parachiotHeb = ["דברים", "ואתחנן", "עקב", "ראה", "שופטים", "כי תצא", "כי תבוא", "ניצבים", "וילך", "האזינו", "וזאת הברכה"];

export default function MenuRead({ currentParams, handleChangeParams }: MenuReadType) {
    const [isOpen, setIsOpen] = useState(false);
    let handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        let { value, name } = e.target;
        let defaultParams = name === "lang" ? currentParams
            :
            {
                paracha: 0,
                perek: 0,
                day: 0
            }
        handleChangeParams({
            ...currentParams,
            ...defaultParams,
            [e.target.name]: value
        })
        setIsOpen(false)
    }
    return <Box sx={menuReadStyle.boxMenu}>
        <Box
            sx={menuReadStyle.desc}
            onClick={() => {
                setIsOpen(!isOpen)
            }}>
            <TuneOutlinedIcon />
            <Typography variant="subtitle1">Options de lecture</Typography>
            <ArrowForwardIosIcon sx={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)", transition: "all 0.4s ease" }} />
        </Box>
        <Divider />
        <Collapse orientation="vertical" in={isOpen}>
            <Box sx={menuReadStyle.menuContain}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ textAlign: "center" }}>Mode de lecture  </Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            value={currentParams.paracha}
                            select
                            fullWidth
                            size='small'
                            variant="outlined"
                            margin="dense"
                            name="paracha"
                            onChange={handleChange}
                        >
                            <MenuItem disabled value={0}>
                                <em> Par Paracha</em>
                            </MenuItem>
                            {parachiot.map((element, index) => {
                                return <MenuItem key={index} value={element}>{element} - {parachiotHeb[index]}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            value={currentParams.perek}
                            select
                            size='small'
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            name="perek"
                        >
                            <MenuItem disabled value={0}>
                                <em> Par Chapitre</em>
                            </MenuItem>
                            {Array.from(Array(34).keys()).map((element, index) => {
                                element++;
                                return <MenuItem key={index} value={element}>Chapitre {element} -  פרק {gematriya(element)}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            value={currentParams.day}
                            select
                            size='small'
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            name="day"
                        >
                            <MenuItem disabled value={0}>
                                <em>Par jour de la semaine</em>
                            </MenuItem>
                            {dayOfWeek.map((element, index) => {
                                index += 1;
                                return <MenuItem key={index} value={index}>{element}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ textAlign: "center" }}>Langue</Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            value={currentParams.lang}
                            select
                            size='small'
                            fullWidth
                            onChange={handleChange}
                            variant="outlined"
                            margin="dense"
                            name="lang"
                        >
                            {Array.from([{ abg: "fr", lang: "Francais" }, { abg: "he", lang: "Hebreu" }]).map((element, index) => {
                                return <MenuItem key={index} value={element.abg}>{element.lang}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                </Grid>

            </Box>
        </Collapse>
    </Box>;
}
