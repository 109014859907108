import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton, Button, Divider } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from "swiper";
import lectureContainerStyle from './lectureContainerStyle';
import gematriya from 'gematriya';
import { dayOfWeek, alyot } from 'ressources/data/interpreter';

export default function LectureContainer({ data, style, showTitle }: LectureContainerType) {
    let { innerBox, pskMode } = lectureContainerStyle;
    const [alya, setAlya] = useState(0);
    const [perekDay, setPerekDay] = useState(0);
    let { extraRef } = data;
    useEffect(() => {
        if (data.text) {
            if (perekDay > 3 && data.text.length === 4) {
                setPerekDay(0);
            }
        }

    }, [perekDay, data.text])
    let Title = () => {
        return data.type === "perek" ?
            <Typography variant={"h6"}>{`פרק ${gematriya(+data.ref)} - Chapitre ${+data.ref} `}</Typography>
            :
            <>
                {data.type === "day" ?
                    <Typography variant={"h6"}>{dayOfWeek[+(data.ref) - 1]}</Typography>
                    :
                    <Typography variant={"h6"}>{`Paracha ${data.ref}`}</Typography>
                }
            </>
    }
    let Content = () => {


        let content;
        if (data.type === "paracha") {
            content = (data.text[alya || 0] as string[]);
        } else if (data.type === "day") {
            let offset = perekDay > 3 && data.text.length === 4 ? 1 : 0;
            content = (data.text[perekDay - offset || 0] as string[]);
        } else {
            content = data.text;
        }
        return <>

            {
                content.map((paragraph: string | string[], index: number) => {
                    return <Typography key={index} variant={data.lang === "he" ? "hebrewFont" : "body1"} component="p" style={{ margin: "0 15px" }} sx={{ ...pskMode(data.lang as 'he' | 'fr', index + 1), lineHeight: data.lang === "fr" ? "2.5rem" : "" }} dangerouslySetInnerHTML={{ __html: `${paragraph}` }} />
                })
            }
        </>
    }
    return <Box sx={innerBox} style={style} dir={`${data.lang === 'he' && "rtl"}`}>
        {(data.type && showTitle) && <Title />}
        {(data.type === "paracha" || data.type === "day") && <Box sx={{ backgroundColor: "primary.main" }}>
            <Divider />
            <Swiper
                modules={[Navigation, FreeMode]}
                slidesPerView={2}
                autoHeight
                watchOverflow
                spaceBetween={10}
                freeMode
                dir="ltr"
                style={{ padding: "10px" }}
                breakpoints={{
                    744: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 7
                    }
                }}
            >

                {data.type === "paracha" ? alyot.fr.map((alyaTxt, index) => {
                    return <SwiperSlide key={index} ><Button variant='contained' sx={{ width: "100%" }} disabled={index === alya} onClick={() => { setAlya(index) }}>{alyaTxt}</Button></SwiperSlide>
                })
                    :
                    Array(data.text.length).fill("").map((perek, index) => {
                        return <SwiperSlide key={index} ><Button variant='contained' sx={{ width: "100%" }} disabled={index === perekDay} onClick={() => { setPerekDay(index) }}>perek {extraRef !== undefined && (extraRef + index + 1)}</Button></SwiperSlide>
                    })}
            </Swiper>
        </Box>}
        {data.text ?
            <Box sx={{ padding: "10px 20px" }}>
                <Content />
            </Box>
            :
            <Box sx={{ padding: "10px" }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box >
        }
    </Box>
        ;
}
