import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { parachiot } from "../MenuRead"
import { alyot } from "ressources/data/interpreter";
export default function TableMenu({ data, handleRead }: { data: boolean[][], handleRead: (ref: number[]) => void }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>

                        <TableCell sx={{ backgroundColor: "#EBE4B7", border: "1px solid rgba(224, 224, 224, 1)" }}></TableCell>
                        {alyot.fr.map((alya, index) => {
                            return <TableCell sx={{ backgroundColor: "#EBE4B7", border: "1px solid rgba(224, 224, 224, 1)" }} key={index}>{alya}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((data, pIndex) => {
                        return <TableRow key={pIndex}>
                            <TableCell sx={{ backgroundColor: "#EBE4B7", textAlign: "center" }}>{parachiot[pIndex]}</TableCell>
                            {data.map((isReaded, index) => {

                                return <TableCell
                                    sx={{
                                        color: isReaded ? "rgba(0,0,0,0.5) " : "inherit", border: "1px solid rgba(224, 224, 224, 1)",
                                        cursor: isReaded ? "not-allowed" : "pointer",
                                        textAlign: "center",
                                        textDecorationLine: isReaded ? "none" : "underline",
                                        backgroundColor: isReaded ? "#EDE7A2" : "initial"
                                    }}
                                    onClick={() => {
                                        if (!isReaded) {
                                            handleRead([pIndex, index])
                                        }
                                    }}
                                    key={index}>
                                    {isReaded ? "Déja lu" : "Disponible"}
                                </TableCell>
                            })}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
