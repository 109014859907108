import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import dailyLectureStyle from './dailLectureStyle';
import LectureContainer from '../LectureContainer';
import axios from 'axios';
export default function DailyLecture() {
    const [data, setdata] = useState<undefined | { fr: dataReadStruct, he: dataReadStruct }>(undefined);
    useEffect(() => {
        if (!data) {
            axios.get("https://www.seferdevarim.net/api/general/daily").then((res: any) => {
                let handledData = {
                    he: {
                        type: "perek",
                        lang: "he",
                        text: res.data.he as string[],
                        ref: "0"
                    },
                    fr: {
                        type: "perek",
                        lang: "fr",
                        text: res.data.fr as string[],
                        ref: "0"
                    }
                }
                setdata(handledData);
            }).catch((e) => {
                console.error(e)
            })
        }

    }, [data])


    let { outerBox } = dailyLectureStyle;
    return (
        <Box sx={dailyLectureStyle}>
            <Typography variant="h3">Cinq psoukim par jour</Typography>
            <Box sx={outerBox}>
                {data ? <>
                    <LectureContainer data={data.he} />
                    <LectureContainer data={data.fr} />
                </>
                    : <Box sx={{ width: "100%", " > *": { marginY: "10px" } }}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Box>
                }

            </Box>
        </Box>
    )
}
