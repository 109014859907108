import React, { useState } from 'react';
import NotFound from 'common/pages/404';
import Nav from 'common/components/Nav';
import Home from './common/pages/Home';
import Book from './common/pages/Book';
import Footer from 'common/components/Footer';
import { RecoilRoot } from "recoil"
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider, Box, Fab, Button, Menu, MenuItem, Typography } from "@mui/material";
import { mainTheme } from 'ressources/Mui-styles/MuiTheme';
import YouTubeIcon from '@mui/icons-material/YouTube';

import "./ressources/Sass/global.scss";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";

import global from 'globalStyle';
import Read from 'common/pages/Read';
import ScrollTop from 'common/components/ScrollTop';
import Activation from 'common/pages/Activation';
function App() {

  let lastWindowScroll = window.scrollY;
  const [reducNav, setReducNav] = useState(false);
  window.addEventListener('scroll', () => {
    if (lastWindowScroll >= window.scrollY) {
      if (reducNav) {
        setReducNav(false)
      }
    } else {
      if (!reducNav) {
        setReducNav(true)
      }
    }
    lastWindowScroll = window.scrollY
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuIsOpen = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    if (e.target.innerText === "En Euro") {
      window.open("https://www.paypal.com/donate/?hosted_button_id=VU6XRRBW6LGQ6", '_blank');
    } else if (e.target.innerText === "En Shekel") {
      window.open("https://www.paypal.com/donate/?hosted_button_id=JKVQM73L4VP84", '_blank')
    };
    setAnchorEl(null);
  };

  return (
    <div className="App" >
      <ScrollTop />
      <ThemeProvider theme={mainTheme}>
        <Nav toReduce={reducNav} />
        <Box sx={global.fabBox}>
          <Button
            sx={global.donateBtn}
            id="demo-positioned-button"
            variant="contained"
            aria-controls={menuIsOpen ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"

            aria-expanded={menuIsOpen ? 'true' : undefined}
            onClick={handleClick}
          >
            <Typography
              variant="button">
              FAIRE UN DON
            </Typography>

          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={menuIsOpen}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              onClick={handleClose}
            >En Euro</MenuItem>
            <MenuItem onClick={handleClose}
            >En Shekel</MenuItem>
          </Menu>
          <Fab sx={global.youtubeFab} target={"_blank"} title="Chaine Youtube" href="https://www.youtube.com/channel/UCHHWmFSnT8g5xABrvsk2vLw" >
            <YouTubeIcon sx={{ color: "#fff" }} />
          </Fab>
        </Box>

        <Box sx={global} >
          <RecoilRoot>
            <Routes >
              <Route path="/" element={<Home />} />
              <Route path="/introduction_sefer_devarim.docx" />
              <Route path="/lecture" element={<Read />} />
              <Route path="/book/:id" element={<Book />} />
              <Route path="/activation/:id" element={<Activation />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </RecoilRoot>
          <Footer />
        </Box>

      </ThemeProvider>
    </div>
  );
}

export default App;
