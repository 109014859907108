import React, { useState, useEffect } from 'react'
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Card, CardMedia, Modal, Box, Typography, CircularProgress } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import videoRavStyle from './videoRavStyle';
import axios from 'axios';
type VideoData = {
    link: string,
    title: string
}
export default function VideoRav() {
    const [currentVideo, setCurrentVideo] = useState("");
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<VideoData[] | undefined>(undefined);
    useEffect(() => {
        if (!data) {
            //http://www.seferdevarim.net
            axios.get("https://www.seferdevarim.net/api/video").then((res: any) => {
                setData(res.data as VideoData[]);
            }).catch((e) => {
                console.error(e)
            })
        }
    }, [data])


    return (
        <Box sx={videoRavStyle} style={{ marginBottom: "100px" }}>
            <Typography variant="h3" gutterBottom >
                Videos du rav
            </Typography>
            {data ? <>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    navigation
                    autoHeight
                    spaceBetween={10}
                    breakpoints={{
                        744: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 4
                        }
                    }}
                >
                    {data.map((video, index) => {
                        return <SwiperSlide key={index}>
                            <Card
                                sx={videoRavStyle.card} >

                                <Typography variant={"subtitle1"} component="small">
                                    {video.title}
                                </Typography>

                                <PlayCircleFilledIcon onClick={() => {
                                    setCurrentVideo(video.link);
                                    setOpen(true);
                                }}
                                />
                                <CardMedia
                                    component="img"
                                    image={`https://img.youtube.com/vi/${video.link}/hqdefault.jpg`}
                                    alt={video.title}
                                />
                            </Card>

                        </SwiperSlide>
                    })
                    }

                </Swiper>
            </>
                : <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress className="loader" size={80} sx={{ color: "rgba(0, 0, 0, 0.11)" }} />
                </Box>}

            <Modal
                sx={videoRavStyle.modal}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <iframe width="560" height="315" src={`https://www.youtube.com/embed/${currentVideo}?rel=0`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Modal>
        </Box>
    )
}
