import React, { useState } from 'react';
import { TextField, Button, Typography, Box, CircularProgress, Alert, AlertTitle } from '@mui/material';
import { getFormData } from 'ressources/utils/utils';
import { ReactComponent as FooterSvg } from "ressources/images/svg/footerSep.svg";
import footerStyle from './footerStyle';
import CheckForm from 'ez-check-form';
import Lottie from 'lottie-react';
import sendMessage from 'ressources/lottie/sendMessage.json';
import axios from 'axios';

export default function Footer() {
    const [send, setSend] = useState(false);
    const [onLoad, setOnLoad] = useState(false);
    const [errorForm, setErrorForm] = useState<any>();
    let handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        let formData: { [key: string]: any };
        e.preventDefault();
        formData = getFormData(e) as any;
        formData.lastName = formData["lastName"].replaceAll(" ", "");
        formData.firstName = formData["firstName"].replaceAll(" ", "");
        let checkForm = new CheckForm(formData,
            [
                { key: ["firstName", "lastName"], perform: [['hasSpecialChars', 'isEmptyString'], 'reverse'] },
                { key: ["lastName", "firstName"], perform: ['checkStrSize', 'lesser-equal', 30] },
                { key: ["lastName", "firstName"], perform: ['checkStrSize', 'greater-equal', 2] },
                { key: ["message"], perform: ['checkStrSize', 'greater-equal', 2] },
                { key: ["mail"], perform: ["isMail"] }
            ]
        );
        checkForm.enhanceCheckData();
        if (checkForm.isInvalidForm) {
            setErrorForm(checkForm.getModelErrorStructure());
        } else {
            setOnLoad(true);
            axios.post("https://www.seferdevarim.net/api/general/contact", formData)
                .then(response => {
                    if (response.status === 200) {
                        setSend(true);
                        setOnLoad(false);
                        window.setTimeout(() => {
                            setSend(false);
                        }, 10000);
                    } else {
                        console.log(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }


    }
    return (
        <>
            <FooterSvg style={{ position: "relative", top: "1px", width: "100%", fill: "#E4A737", display: "block" }} />
            <Box sx={footerStyle}>



                {send ? <>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <Lottie style={{ width: "20%" }} animationData={sendMessage} />
                        <Alert severity="success">
                            <AlertTitle>Message recu</AlertTitle>
                            Votre message a bien été envoyée.
                        </Alert>
                    </Box>
                </> :
                    <>
                        <Typography variant="h5">
                            Contactez nous
                        </Typography>

                        <form onSubmit={handleSubmit}>

                            <Box sx={footerStyle.boxInput}>
                                <TextField
                                    name="firstName"
                                    variant="filled"
                                    error={errorForm?.firstName.hasError}
                                    helperText={errorForm?.firstName.hasError && "Le champ ne doit pas comporter de caractères spéciaux et avoir entre 2 et 15 lettres"}
                                    required
                                    placeholder='*Nom' />

                                <TextField
                                    name="lastName"
                                    required
                                    error={errorForm?.lastName.hasError}
                                    helperText={errorForm?.lastName.hasError && "Le champ ne doit pas comporter de caractères spéciaux et avoir entre 2 et 15 lettres"}
                                    variant="filled"
                                    placeholder='*Prenom' />
                            </Box>
                            <Box sx={footerStyle.boxInput}> <TextField
                                name="mail"
                                required
                                variant="filled"
                                error={errorForm?.mail.hasError}
                                helperText={errorForm?.mail.hasError && "Veuillez entrer une adresse mail valide"}
                                type="email"
                                placeholder='*Adresse email' />

                                <TextField
                                    name="phone"
                                    type="phone"
                                    variant="filled"
                                    placeholder='Telephone' />
                            </Box>




                            <TextField
                                sx={{ width: { sm: "100%" } }}
                                name="message"
                                variant="filled"
                                required
                                placeholder='*Message'
                                multiline
                                rows={4}
                            />

                            {onLoad ?
                                <CircularProgress sx={{ marginY: "20px" }} /> :
                                <Button variant="contained" type="submit">Envoyer</Button>}
                        </form>
                    </>}
            </Box>
        </>

    )
}
