

let videoRavStyle = {
    card: {
        position: "relative",
    },
    small: {
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        fontWeight: "bold",
        backgroundColor: "rgba(60, 60, 60, 0.27)",
        color: "#fff",
        lineHeight: "initial",
        textAlign: "center",
        padding: "10px",
        minHeight: "10%"
    },
    "*:not(.loader) > svg": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: {
            xs: "30vw",
            sm: "10vw",
            md: "62px"
        },
        color: "#fff",
        cursor: "pointer"

    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        iframe: {
            width: {
                xs: "100%",
                sm: "560px",
            }
        }
    },
    ".swiper-button-next,.swiper-button-prev": {
        color: "grey!important",
        "@media (min-width:600px)": {
            opacity: "0.5",
            "&:hover": {
                opacity: 1
            }
        },

    }
} as const;
export default videoRavStyle;