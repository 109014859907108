import React from 'react';
import Lottie from "lottie-react";
import animation from "ressources/lottie/404Animation.json";
import { Box, Container, Typography } from '@mui/material';
export default function NotFound() {
    return (
        <Container>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="h2" style={{ fontWeight: "bold" }}>Erreur 404 </Typography>
                <Typography variant="h4">La page n'existe pas</Typography>
                <Lottie style={{ width: "50%" }} animationData={animation} />

            </Box>
        </Container>
    )
}
